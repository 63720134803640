import { isEmpty } from "lodash";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function RequisitionApproveFooter({
  requisition,
  canPerformAction,
  canReservedUserSend,
  reciever,
  approveAndClose,
  canReservedUserPerformAction,
  reject,
  preSelectedSignatoryData,
  approveOnly,
  id,
  canApproveAndClose,
  location,
}) {
  if (requisition?.requisitiontracks) {
    const isClosed = requisition?.requisitiontracks.find((el) => el.closedHere);
    if (isClosed) return null;
  }
  return (
    <>
      {canPerformAction(requisition) &&
        (requisition.filledFormData || !isEmpty(preSelectedSignatoryData) ? (
          <Modal.Footer className="d-print-none variant-2 gap-3">
            <Button
              onClick={() => reject()}
              variant="danger"
              className="text-white px-3 reject"
            >
              Reject
            </Button>

            <div className="proceed-actions">
              {reciever ? (
                <Link
                  to={`/requisition/request/approve-and-send/${id}`}
                  state={{ backgroundLocation: location }}
                  className="btn btn-white border bg-white"
                >
                  Approve & Sent
                </Link>
              ) : (
                <Button
                  className=""
                  onClick={() => approveAndClose()}
                  variant="primary"
                >
                  Approve & Close
                </Button>
              )}
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer className="d-print-none variant-2">
            <Button
              onClick={() => reject()}
              variant="danger"
              className="text-white px-3 reject"
            >
              Reject
            </Button>

            <div className="proceed-actions">
              <Link
                to={`/requisition/request/approve-and-send/${id}`}
                state={{ backgroundLocation: location }}
                className="btn btn-white border bg-white"
              >
                Approve & Sent
              </Link>

              {canApproveAndClose(requisition) && (
                <Button
                  className="approve-and-close"
                  onClick={() => approveAndClose()}
                  variant="primary"
                >
                  Approve & Close
                </Button>
              )}
            </div>
          </Modal.Footer>
        ))}
      {!canPerformAction(requisition) &&
      (canReservedUserPerformAction || canReservedUserSend) ? (
        <Modal.Footer className="d-print-none variant-2">
          <Button
            onClick={() =>
              reject({
                isReservedUser: true,
              })
            }
            variant="danger"
            className="text-white px-3 reject"
          >
            Reject
          </Button>

          <div className="proceed-actions">
            {canReservedUserSend && (
              <>
                <Button
                  className="btn btn-light-blue border"
                  onClick={() => approveOnly()}
                >
                  Approve
                </Button>
                <Link
                  to={`/requisition/request/approve-and-send/${id}`}
                  state={{ backgroundLocation: location }}
                  className="btn btn-white border bg-white"
                >
                  Approve & Sent
                </Link>
              </>
            )}
            {canReservedUserPerformAction ? (
              <Button
                className="approve-and-close"
                onClick={() =>
                  approveAndClose({
                    isReservedUser: true,
                  })
                }
                variant="primary"
              >
                Approve & Close
              </Button>
            ) : null}
          </div>
        </Modal.Footer>
      ) : null}
    </>
  );
}
