import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";

export default function WarehouseSettingsModal({
  warehouseSetting,
  show,
  setShow,
}) {
  const { backendUrl, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const submit = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/add-warehouse-settings`,
        "POST",
        token,
        payload
      ),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        setEdit(false);
      },
      onError: () => {
        setLoading(false);
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      inventoryWebHook: "",
      locationID: "",
      W_ID: warehouseSetting?.W_ID,
    },
    onSubmit: (values) => {
      const payload = [
        {
          W_ID: values.W_ID,
          Settings_Name: "Inventory Webhook URL",
          Settings: values.inventoryWebHook,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "Location ID",
          Settings: values.locationID,
        },
      ];

      submit.mutate(payload);
      //   console.log(payload);
    },
  });

  const { data } = useQuery(
    "GET_WAREHOUSE_SETTINGS",
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/warehouse-settings/${
          warehouseSetting?.W_ID
        }`,
        "GET"
      ),
    {
      onSuccess: ({ data }) => {
        formik.setFieldValue("inventoryWebHook", data[0]?.Settings || "");
        formik.setFieldValue("locationID", data[1]?.Settings || "");
      },
    }
  );

  return (
    <Modal onHide={() => setShow(false)} show={show} centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            {" "}
            {warehouseSetting?.warehouseName} Settings
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className=" mb-3">
            <Form.Group>
              <Form.Label htmlFor="inventoryWebHook ">
                Inventory Webhook URL
              </Form.Label>
              <Form.Control
                name="inventoryWebHook"
                disabled={!edit}
                id="inventoryWebHook"
                placeholder="Enter inventory webhook"
                onChange={formik.handleChange}
                value={formik.values.inventoryWebHook}
              />
            </Form.Group>
          </div>
          <div className=" mb-3">
            <Form.Group>
              <Form.Label htmlFor="locationID ">Location ID</Form.Label>
              <Form.Control
                name="locationID"
                id="locationID"
                disabled={!edit}
                placeholder=" Enter Location ID"
                onChange={formik.handleChange}
                value={formik.values.locationID}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={edit}
            variant="outline-primary"
            type="button"
            onClick={() => setEdit(true)}
          >
            Edit
          </Button>
          <Button type="submit" disabled={loading || !edit}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
